import en from 'vuetify/es5/locale/en';
import ru from 'vuetify/es5/locale/ru';
import zh from 'vuetify/es5/locale/zh-Hans';

const locales = {
  en,
  ru,
  zh
};

export default locales;
